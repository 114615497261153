import React from "react";
import { Link } from "react-router-dom";
import "./FrontPage.css";

const FrontPage = () => {
  return (
    <div className="frontpage-layout">
      <div className="menu">
        <ul>
        <li><Link to="/">BILLI&nbsp; THANNER</Link></li>
<li><Link to="/Work">WORK</Link></li>
<li><Link to="/About">ABOUT</Link></li>
<li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
<li><Link to="/Press">PRESS</Link></li>
<li><Link to="/Contact">CONTACT</Link></li>

        </ul>
      </div>
     
    </div>
  );
};

export default FrontPage;
