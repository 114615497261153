// About.js
import React from 'react';
import { Link } from "react-router-dom";
import BackButton from "../components/BackButton.js"
import "./About.css"
import BurgerMenu from '../components/BurgerMenu.js';

const About = () => {
  return (
    <div ><div className="topblocker" ><BackButton/><BurgerMenu/></div>
  <div className="container-grid"style={{  marginTop:"300px"}}>
    <div className="menu2">
     <ul> <li><Link to="/Work">WORK</Link></li>
<li><Link to="/About">ABOUT</Link></li>
<li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
<li><Link to="/Press">PRESS</Link></li>
<li><Link to="/Contact">CONTACT</Link></li></ul></div>
<div className="placeholder"></div>

<div className="text-container textleft" lang="DE"style={{ position: 'relative', top: '-18px',textAlign: "justify",overflow:"hidden"}}>
  <span style={{ fontSize: '267px', padding:"0px", float:"left", lineHeight:"0.2" ,position:"relative", top:"-85px"}}>&bdquo;</span>
  ALS KONZEPTKÜNSTLERIN BIN ICH SEIT ÜBER 20 JAHREN BESTREBT, GESELLSCHAFTLICH
  RELEVANTE THEMEN IN MEINEN WERKEN ZU VISUALISIEREN UND DIE BETRACHTER ZUM
  NACHDENKEN ANZUREGEN. MEINE KUNSTWERKE ZEICHNEN SICH DURCH EINE TIEFE
  INHALTLICHE AUSEINANDERSETZUNG MIT DEM JEWEILIGEN THEMA UND DURCH EINE
  ÄSTHETISCH ANSPRUCHSVOLLE UMSETZUNG AUS. EIN BESONDERES ANLIEGEN IST MIR
  
  DABEI DIE BETRACHTUNG DES ZEITGENÖSSISCHEN KUNSTGESCHEHENS IM KONTEXT DER
  KUNSTGESCHICHTE. 
  
  DABEI VERSTEHE ICH MEINE ARBEIT NICHT ALS ISOLIERTES WERK,
  SONDERN ALS TEIL EINES UMFASSENDEN<span style={{ fontSize: '267px', padding:"0px", float:"right", lineHeight:"0.3" ,position:"relative",top:"65px"}}>&ldquo;</span>  DISKURSES, DER IN BEZUG AUF
  ZEITGENÖSSISCHE  STRÖMUNGEN <span style={{display:"none", fontSize: '267px', padding:"0px", float:"right", lineHeight:"0.2" ,position:"relative",top:"66px"}}>&ldquo;</span> UND ENTWICKLUNGEN STEHT.
  
</div>

<div className="text2-3-4-container">
  <div className="text-container textmid1" lang="DE">BILLI THANNER IST EINE KONZEPT KÜNSTLERIN.
SIE SETZT SICH MIT IHRER KUNST FÜR EINE WELT OHNE VORURTEILE
EIN. IN IHREN WERKEN REFLEKTIERT SIE DIE KOMPLEXITÄT
DES MENSCHLICHEN DENKENS UND BETONT DIE
NOTWENDIGKEIT, JEGLICHE SCHUBLADEN UND VORURTEILE
ZU DURCHBRECHEN.</div>
<div className="text3-4-wrapper">
  <div className="text-container" lang="DE">IHR WERK „DAS SCHUBLADISIERTE
GEHIRN“ AUS HOLZ UND
LICHT MIT VIELEN SCHUBLADEN,
ERSCHAFFEN VOR 16 JAHREN,
SYMBOLISIERT IHREN WUNSCH
NACH EINER GESELLSCHAFT, DIE
FREI VON EINSCHRÄNKUNGEN
UND OFFEN FÜR DIE VIELFALT
DER WELT IST. ALLES IST MIT ALLEM
VERBUNDEN. GEBOREN AUF
DER ERDE UND AUFGEWACHSEN
AUF DER ERDE LEBT UND ARBEITET
BILLI THANNER ALS WELTBÜRGERIN,
DIE BEWUSST ÜBER
POLITISCHE SCHRANKEN HINAUS
DENKT.</div>
  <div className="text-container" lang="DE">IHR FOKUS LIEGT AUF DER GEMEINSAMEN MENSCHLICHKEIT,
DIE SIE ALS ÜBERGEORDNETES PRINZIP BETRACHTET.
THANNER SIEHT DEN MENSCHEN ALS UNIVERSELLES
BINDEGLIED, DAS ÜBER GEOGRAFISCHE
UND KULTURELLE GRENZEN SICH HINWEG VERBINDET.
IN IHREN KÜNSTLERISCHEN ARBEITEN UND IHREM
STIL BETONT SIE DIE UNIVERSELLE BEDEUTUNG
DER MENSCHLICHKEIT. SIE BLICKT AUF DIE KREATIVE
SPRACHE DER KUNST, UM MENSCHEN ZU INSPIRIEREN
UND VERBINDUNGEN ZU SCHAFFEN.
<div className="spacer"></div>
SIE STREBT DANACH, ALS KÜNSTLERIN UND WELTBÜRGERIN
PRÄSENT ZU SEIN, UM IHRE BOTSCHAFT
DER FREIHEIT VON VORURTEILEN UND FREIHEIT DER
KUNST SOWIE IHRER HINGABE AN DIE GEMEINSAME
MENSCHLICHKEIT ZU TEILEN. IHRE WERKE SOLLEN
DARAN ERINNERN, DASS KEIN MENSCH AUFGRUND
SEINER HAUTFARBE, SEXUELLER ORIENTIERUNG,
HERKUNFT NICHT WENIGER WERT IST ALS JEDER ANDERE.</div>
</div>
</div>
</div>

    </div>

  );
};

export default About;
