
import React, { useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import BackButton from "../components/BackButton.js"
import ScrollToTopOnLoad from '../components/scrolltotoponload.js';
import BurgerMenu from '../components/BurgerMenu.js';
import './Artfairs.css';

const Artfairs = () => {
  const [artFairs, setartFairs] = useState([]); // Temporärer State für die Daten
  const [loading, setLoading] = useState(true); // Ladeanzeige
  
  useEffect(() => {
    const fetchartFairs = async () => {
      try {
        const response = await fetch('/data/artfairs.json', {
          method: 'GET', // HTTP-Methode
          headers: {
            'Content-Type': 'application/json', // Erwartetes Format
            'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0',
            'Pragma': 'no-cache'
          }
        });// JSON aus dem public-Ordner abrufen
        if (!response.ok) throw new Error('Fehler beim Laden der JSON-Daten');
        const data = await response.json();
        setartFairs(data); // Temporär in den State setzen
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchartFairs();
  }, []); // Lädt die Daten nur beim ersten Rendern
  

  

  return (
    <div ><div className="topblocker" ><BackButton/><BurgerMenu/> </div>
       <nav className="menu4-mobile">
    
    <ul> 
    <li><Link to="/Work">WORK</Link></li>
    <li><Link to="/About">ABOUT</Link></li>
<li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
<li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
<li><Link to="/Artfairs">ARTFAIRS</Link></li>
<li><Link to="/Projects">PROJECTS/PERFORMANCES</Link></li>
<li><Link to="/Press">PRESS</Link></li>
<li><Link to="/Contact">CONTACT</Link></li>

</ul>
     </nav>
      <div className="exhibitions-container">
      <div className="placeholder"></div>
      <nav className="menu4">
    
     <ul> <li><Link to="/Work">WORK</Link></li>
<li><Link to="/About">ABOUT</Link></li>
<li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
<li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
<li><Link to="/Artfairs">ARTFAIRS</Link></li>
<li><Link to="/Projects">PROJECTS/PERFORMANCES</Link></li>
<li><Link to="/Press">PRESS</Link></li>
<li><Link to="/Contact">CONTACT</Link></li></ul>
      </nav>
      
      <div className="exhibitions-grid"><ScrollToTopOnLoad/>
        {artFairs.map((exhibition, index) => {
          const [year, ...textParts] = exhibition.split(" ");
          const text = textParts.join(" ");
          return (
            <div key={index} className="exhibition-item">
              <span className="exhibition-year">{year}</span>
              <span className="exhibition-text">{text}</span>
            </div>
          );
        })}
     
    </div></div></div>
  );
};

export default Artfairs;

