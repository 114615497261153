import React, { useEffect } from "react";

const ScrollToTopOnLoad = () => {
    useEffect(() => {
        const scrollableElement = document.querySelector("[style*='overflow']");
        if (scrollableElement) {
          scrollableElement.scrollIntoView({ behavior: "auto", block: "start" });
        }
      }, []);
       // Leeres Array sorgt dafür, dass es nur einmal beim Laden ausgeführt wird

  return null;
};

export default ScrollToTopOnLoad;
