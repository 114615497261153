import React, { useState, useEffect, useRef } from 'react';
import './Work.css';
import BackButton from '../components/BackButton';

import ArrowR from "../images/arrowr.png"
import ArrowL from "../images/arrowl.png"
import BurgerMenu from '../components/BurgerMenu.js';






const Work = () => {

  const [isVisible, setIsVisible] = useState(true);
  const [moveUp, setMoveUp] = useState(false);

  useEffect(() => {
    const checkVisibility = () => {
      if (window.innerWidth > 875 && window.innerHeight < 940) {
        setIsVisible(false);
        setMoveUp(true);
      } else {
        setIsVisible(true);
        setMoveUp(false);
      }
    };

    // Initial check
    checkVisibility();

    // Event listener for resizing
    window.addEventListener("resize", checkVisibility);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", checkVisibility);
    };
  }, []);

 

  const [worksData, setWorksData] = useState([]); // Temporärer State für die Daten
  const [loading, setLoading] = useState(true); // Ladeanzeige
  
  useEffect(() => {
    const fetchWorksData = async () => {
      try {
        const response = await fetch('/data/works.json', {
          method: 'GET', // HTTP-Methode
          headers: {
            'Content-Type': 'application/json', // Erwartetes Format
            'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0',
            'Pragma': 'no-cache'
          }
        }); // JSON aus dem public-Ordner abrufen
        if (!response.ok) throw new Error('Fehler beim Laden der JSON-Daten');
        const data = await response.json();
        setWorksData(data); // Temporär in den State setzen
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchWorksData();
  }, []); // Lädt die Daten nur beim ersten Rendern
  

 

 

  // Deklaration von States
  const [activeIndex, setActiveIndex] = useState(1); // Der aktuelle Abschnitt
  const [targetIndex, setTargetIndex] = useState(1); // Zielabschnitt
  const [popupVisible, setPopupVisible] = useState(false); // Popup-Sichtbarkeit
  const [popupIndex, setPopupIndex] = useState(null); // Index des aktuell angezeigten Bildes im Popup

  const sectionsRefs = useRef([]); // Referenzen zu den Sektionen
  const animationRef = useRef(null); // Animation-Frame-Referenz

  const duration = 100;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY; // Aktuelle Scrollposition
      let closestIndex = -1;
      let closestDistance = Infinity;

      sectionsRefs.current.forEach((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const sectionTarget = rect.top + rect.height * 0.25 + window.scrollY; // Oberste 25% als Ziel

          const distance = Math.abs(scrollPosition - sectionTarget);
          if (distance < closestDistance) {
            closestDistance = distance;
            closestIndex = index;
          }
        }
      });

      setTargetIndex(closestIndex); // Setze den Zielabschnitt
    };

    const animateTransition = (startTime) => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }

      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Fortschritt zwischen 0 und 1

      const interpolatedIndex = activeIndex + (targetIndex - activeIndex) * progress;
      setActiveIndex(interpolatedIndex);

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(() =>
          animateTransition(startTime)
        );
      }
    };

    const startTransition = () => {
      if (activeIndex !== targetIndex) {
        animationRef.current = requestAnimationFrame(() =>
          animateTransition(new Date().getTime())
        );
      }
    };

    window.addEventListener("scroll", handleScroll);
    startTransition();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [targetIndex, activeIndex]);

  // Öffnet das Popup und setzt den Inhalt
  const openPopup = (index) => {
    setPopupIndex(index);
    setPopupVisible(true);
  };

  // Schließt das Popup
  const closePopup = () => {
    setPopupVisible(false);
    setPopupIndex(null);
  };

  // Navigiert zum nächsten Bild
  const prevImage = () => {
    if (popupIndex > 0) {
      setPopupIndex(popupIndex - 1); // Gehe zum vorherigen Bild
    } else {
      setPopupIndex(worksData.length - 1); // Gehe zum letzten Bild, wenn am Anfang
    }
  };
  
  const nextImage = () => {
    if (popupIndex < worksData.length - 1) {
      setPopupIndex(popupIndex + 1); // Gehe zum nächsten Bild
    } else {
      setPopupIndex(0); // Gehe zum ersten Bild, wenn am Ende
    }
  };
  
  return (
    <div>
      <div className="topblocker">
        <BackButton />
        <BurgerMenu />
      </div>
      <div className="work-page">
      <aside
        className={`side-menu ${moveUp ? "move-up" : ""}`}
        style={moveUp ? { transform: "translateY(-170px)" } : {}}
      >
        {isVisible && <h1>WORK</h1>}
          <ul>
            {worksData.map((work, index) => (
              <li key={work.id}>
                <a
                  href={`#section-${work.id}`}
                  className={index === Math.round(activeIndex) ? "active" : ""}
                >
                  {work.title}
                </a>
              </li>
            ))}
          </ul>
        </aside>
        <main className="work-content">
          <div className="grid-container">
            {worksData.map((work, index) => (
              <section
                className="work-section"
                key={work.id}
                onClick={() => openPopup(index)}
              >
                <img src={work.url} alt={work.title} className="work-image" />
                <div className="work-info">
                  <h2>{work.title}</h2>
                  <p>{work.year}</p>
                </div>
                <div
                  className="anpassenscroll"
                  ref={(el) => (sectionsRefs.current[index] = el)}
                  id={`section-${work.id}`}
                  key={work.id}
                ></div>
              </section>
            ))}
          </div>
        </main>
      </div>
      <div style={{ height: "30vh", width: "100%" }}></div>

      {/* Popup */}
      {popupVisible && popupIndex !== null && (
  <div className="popup-overlay">
    <div className="popup-content">
      {/* Close-Button */}
      <button className="close-buttonpop" onClick={closePopup}><div style={{height:"100px",width:"100px",position:"relative",userSelect:"none", left:"-30px"}}>x</div></button>

      {/* Zurück-Pfeil */}
      <img
        src={ArrowL}
        alt="Previous"
        className="arrow-left"
        onClick={prevImage} // Funktion für vorheriges Bild
      />

      {/* Bild */}
      <div className="popup-image-container">
        <img
          className="popup-image"
          src={worksData[popupIndex].url}
          alt={worksData[popupIndex].title}
        />
      </div>

      {/* Text */}
      <div className="popup-text">
     
        <p>{worksData[popupIndex].title}</p>
        <p className="technik">{worksData[popupIndex].technik}</p>
        <p className="format">{worksData[popupIndex].format}</p>
        <p>{worksData[popupIndex].year}{worksData[popupIndex].serie}</p>
        <br/>
        <p className="deskription">{worksData[popupIndex].deskription}</p>
      </div>

      {/* Weiter-Pfeil */}
      <img
        src={ArrowR}
        alt="Next"
        className="arrow-right"
        onClick={nextImage} // Funktion für nächstes Bild
      />
    </div>
  </div>
)}




    </div>
  );
};



export default Work;
