
import { Link } from "react-router-dom";
import BackButton from "../components/BackButton.js"
import ScrollToTopOnLoad from '../components/scrolltotoponload.js';
import BurgerMenu from '../components/BurgerMenu.js';
import './Projects.css';
import React, { useState, useEffect} from 'react';
const Projects = () => {
  const [projectsData, setprojectsData] = useState([]); // Temporärer State für die Daten
  const [loading, setLoading] = useState(true); // Ladeanzeige
  
  useEffect(() => {
    const fetchprojectsData = async () => {
      try {
        const response = await fetch('/data/projects.json', {
          method: 'GET', // HTTP-Methode
          headers: {
            'Content-Type': 'application/json', // Erwartetes Format
            'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0',
            'Pragma': 'no-cache'
          }
        }); // JSON aus dem public-Ordner abrufen
        if (!response.ok) throw new Error('Fehler beim Laden der JSON-Daten');
        const data = await response.json();
        setprojectsData(data); // Temporär in den State setzen
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchprojectsData();
  }, []); 
  return (
    <div>
      <div className="topblocker">
        <BackButton />
        <BurgerMenu />
      </div>
      <nav className="menu5-mobile">
        <ul>
          <li><Link to="/Work">WORK</Link></li>
          <li><Link to="/About">ABOUT</Link></li>
          <li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
          <li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
          <li><Link to="/Artfairs">ARTFAIRS</Link></li>
          <li><Link to="/Projects">PROJECTS/PERFORMANCES</Link></li>
          <li><Link to="/Press">PRESS</Link></li>
          <li><Link to="/Contact">CONTACT</Link></li>
        </ul>
      </nav>
      <div className="exhibitions-container" >
        <div className="placeholder"></div>
        <nav className="menu5">
        <ul>
          <li><Link to="/Work">WORK</Link></li>
          <li><Link to="/About">ABOUT</Link></li>
          <li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
          <li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
          <li><Link to="/Artfairs">ARTFAIRS</Link></li>
          <li><Link to="/Projects">PROJECTS/PERFORMANCES</Link></li>
          <li><Link to="/Press">PRESS</Link></li>
          <li><Link to="/Contact">CONTACT</Link></li>
        </ul>
      </nav>

        <div className="exhibitions-grid" ><ScrollToTopOnLoad/>
          {projectsData.map((project, index) => {
            const match = project.match(/^(\d{4}(?:\s*-\s*\d{4})?)\s+(.*)/);
  
            return (
              match && (
                <div key={index} className="exhibition-item" >
                  <span className="exhibition-year">
                    {match[1].includes('-') ? match[1] : '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0' + match[1]}
                  </span>
                  <span className="exhibition-text">{match[2]}</span>
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
  
 
  
  
}  
  
  export default Projects;
  
  

