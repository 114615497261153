import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import BurgerMenu from './components/BurgerMenu';
import FrontPage from './pages/FrontPage';
import Work from './pages/Work';
import About from './pages/About';
import Exhibitions from './pages/Exhibitions';
import Press from './pages/Press';
import Contact from './pages/Contact';
import Artfairs from './pages/Artfairs';
import Projects from './pages/Projects';
import Pressphotos from './pages/Pressphotos.js';
import ScrollToTop from './components/scrolltotop.js';
function App() {
  return (
    <Router>
      <ScrollToTop/>
      <MainContent />
    </Router>
  );
}

function MainContent() {
  const location = useLocation();

  return (
    <div>
      {/* Nur BurgerMenu anzeigen, wenn wir uns nicht auf der Startseite befinden */}
      {location.pathname !== '/' && <BurgerMenu />}
     
      {/* Routes unabhängig vom BurgerMenu anzeigen */}
      <Routes>
  <Route path="/" element={<FrontPage />} />
  <Route path="/Work" element={<Work />} />
  <Route path="/About" element={<About />} />
  <Route path="/Exhibitions" element={<Exhibitions />} />
  <Route path="/Artfairs" element={<Artfairs />} />
  <Route path="/Projects" element={<Projects />} />
  <Route path="/Press" element={<Press />} />
  <Route path="/Pressphotos" element={<Pressphotos />} />
  <Route path="/Contact" element={<Contact />} />
</Routes>

    </div>
  );
}

export default App;
