import React, { useState, useEffect, useRef } from 'react';
import './Work.css';


import BackButton from "../components/BackButton.js";
import BurgerMenu from '../components/BurgerMenu.js';




  

// Slideshow Component mit Fade
const Pressphotos = () => {

// Platzhalterbilder
const [pressimages, setpressimages] = useState([]); // Temporärer State für die Daten
  const [loading, setLoading] = useState(true); // Ladeanzeige
  
  useEffect(() => {
    const fetchpressimages = async () => {
      try {
        const response = await fetch('/data/pbilder.json', {
          method: 'GET', // HTTP-Methode
          headers: {
            'Content-Type': 'application/json', // Erwartetes Format
            'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0',
            'Pragma': 'no-cache'
          }
        }); // JSON aus dem public-Ordner abrufen
        if (!response.ok) throw new Error('Fehler beim Laden der JSON-Daten');
        const data = await response.json();
        setpressimages(data); // Temporär in den State setzen
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchpressimages();
  }, []); // Lädt die Daten nur beim ersten Rendern


  const [activeIndex, setActiveIndex] = useState(1); // Der aktuelle Abschnitt
  const [targetIndex, setTargetIndex] = useState(1); // Zielabschnitt
  const sectionsRefs = useRef([]); // Referenzen zu den Sektionen
  const animationRef = useRef(null); // Animation-Frame-Referenz

  const duration = 100;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY; // Aktuelle Scrollposition
      let closestIndex = -1;
      let closestDistance = Infinity;

      sectionsRefs.current.forEach((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const sectionTarget = rect.top + rect.height * 0.25 + window.scrollY; // Oberste 25% als Ziel

          const distance = Math.abs(scrollPosition - sectionTarget);
          if (distance < closestDistance) {
            closestDistance = distance;
            closestIndex = index;
          }
        }
      });

      setTargetIndex(closestIndex); // Setze den Zielabschnitt
    };

    const animateTransition = (startTime) => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }

      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Fortschritt zwischen 0 und 1

      const interpolatedIndex = activeIndex + (targetIndex - activeIndex) * progress;
      setActiveIndex(interpolatedIndex);

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(() =>
          animateTransition(startTime)
        );
      }
    };

    const startTransition = () => {
      if (activeIndex !== targetIndex) {
        animationRef.current = requestAnimationFrame(() =>
          animateTransition(new Date().getTime())
        );
      }
    };

    window.addEventListener("scroll", handleScroll);
    startTransition();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [targetIndex, activeIndex]);



  return (
    <div>
      <div className="topblocker">
        <BackButton />
        <BurgerMenu />
      </div>
      <div className="work-page">
        <aside className="side-menu">
          <h1 className="hugecaption" style={{display:"none"}}>PRESS&nbsp;PHOTOS</h1>
          <ul>
            {pressimages.map((work, index) => (
              <li key={work.id}>
                <a
                  href={`#section-${work.id}`}
                  className={index === Math.round(activeIndex) ? "active" : ""}
                >
                  {work.caption}
                </a>
              </li>
            ))}
          </ul>
        </aside>
        <main className="work-content">
          <div className="grid-container">
            {pressimages.map((work, index) => (
              <section
                className="work-section"
                key={work.id}
                
              >
                <img src={work.url} alt={work.title} className="work-image" />
                <div className="work-info pressinfo">
                  <h2>{work.caption}</h2>
                  <p style={{maxWidth:"440px"}}>{work.caption2}</p>
                  <a
    href={work.download}
    download={work.fname}>  <button style={{marginTop:"30px", maxWidth:"440px"}}>DOWNLOAD</button></a>
                </div>
                <div
                  className="anpassenscroll"
                  ref={(el) => (sectionsRefs.current[index] = el)}
                  id={`section-${work.id}`}
                  key={work.id}
                ></div>
              </section>
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Pressphotos;
