import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './BurgerMenu.css';
import menuButton from '../images/menu-button.png';
const BurgerMenu = () => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Scrollt sofort nach oben
    toggleMenu();
  };

  return (
    <div className="burger-menu">
      <div className="burger-icon" onClick={toggleMenu}>
      <img
      className="burger-icon"
      src={menuButton}
      alt="Menu Button"
      onClick={toggleMenu}
    />
      </div>
      <div className={`menu-content ${open ? 'open' : ''}`}>
        <Link to="/Work" onClick={handleLinkClick}>WORK</Link>
        <Link to="/About" onClick={handleLinkClick}>ABOUT</Link>
        <Link to="/Exhibitions" onClick={handleLinkClick}>EXHIBITIONS</Link>
        <Link to="/Press" onClick={handleLinkClick}>PRESS</Link>
        <Link to="/Contact" onClick={handleLinkClick}>CONTACT</Link>
      </div>
    </div>
  );
};

export default BurgerMenu;
