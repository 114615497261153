import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BackButton.css';
import arrow from "../images/arrow_right_down_icon_155790 Kopie.jpg"
function BackButton() {
  const navigate = useNavigate();

  const goToFrontpage = () => {
    navigate('/');
  };

  return (
    <div className="back-buttonn" onClick={goToFrontpage}>
<img src={arrow}  className="back-button-img" alt="back"style={{ height: "39px", transform: "rotate(90deg)" }}
></img>
      <span className="back-text">BILLI&nbsp; THANNER</span>
    </div>
  );
}

export default BackButton;

