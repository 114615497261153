import React, { useState } from 'react';
import "./cform.css"
const ContactForm = () => {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "a554c6f0-6be9-492d-88fc-e2bfe4cc533e");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    
    <div className="contact-form-container">
      <div className="caption"><p>BILLI &nbsp;THANNER</p><br/>
      
      <a href="https://www.instagram.com/billithanner" target="_blank"rel="noreferrer">https://www.instagram.com/billithanner</a><br/>
      <a href="https://twitter.com/BilliThanner" target="_blank"rel="noreferrer">https://twitter.com/BilliThanner</a>
      
      </div>
      <div className="formconte">
      <p>KONTAKTFORMULAR</p>
      <br/>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="name">NAME</label>
          <input
            type="text"
            id="name"
            name="name"
           
           
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">EMAIL</label>
          <input
            type="email"
            id="email"
            name="email"
            
           
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">NACHRICHT</label>
          <textarea
            id="message"
            name="message"
            rows="5"
           
           
            required
          ></textarea>
        </div>
        <button type="submit">SENDEN</button>
        <span style={{marginTop:"5px"}}>{result}</span>
      </form></div>
    </div>
  );
};

export default ContactForm;
