import React, { useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import BackButton from "../components/BackButton.js"

import BurgerMenu from '../components/BurgerMenu.js';
import './pr.css';
import "./prlayout.css"
import PRChart from './prchart.js';
const Press = () => {
  const [articles, setArticles] = useState([]); // Temporärer State für die Daten
  const [loading, setLoading] = useState(true); // Ladeanzeige
  const [error, setError] = useState(null);

useEffect(() => {
  const fetchArticles = async () => {
    try {
      const response = await fetch('/data/press.json', {
        method: 'GET', // HTTP-Methode
        headers: {
          'Content-Type': 'application/json', // Erwartetes Format
          'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0',
          'Pragma': 'no-cache'
        }
      });
      if (!response.ok) throw new Error('Fehler beim Laden der JSON-Daten');
      const data = await response.json();
      setArticles(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  fetchArticles();
}, []);
// Lädt die Daten nur beim ersten Rendern
  
  

 
    return (
      <div>
        <div className="topblockerp">
          <BackButton />
          <BurgerMenu />
        </div>
  
        <nav className="menu6-mobile">
          <ul>
            <li><Link to="/Work">WORK</Link></li>
            <li><Link to="/About">ABOUT</Link></li>
            <li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
            <li><Link to="/Press">PRESS</Link></li>
            <li><Link to="/Press">PRESS RELEASES</Link></li>
            <li><Link to="/Pressphotos">PRESS PHOTOS</Link></li>
            <li><Link to="/Contact">CONTACT</Link></li>
          </ul>
        </nav>
  
        <nav className="menu6">
          <ul>
            <li><Link to="/Work">WORK</Link></li>
            <li><Link to="/About">ABOUT</Link></li>
            <li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
            <li><Link to="/Press">PRESS</Link></li>
            <li><Link to="/Press">PRESS RELEASES</Link></li>
            <li><Link to="/Pressphotos">PRESS PHOTOS</Link></li>
            <li><Link to="/Contact">CONTACT</Link></li>
          </ul>
        </nav>
  
        <div className="grid-container2">
          <div className="placeholderp"></div>
          <div className="charts">
          {loading ? (
 <br/>
) : (
  articles.map((article, index) => (
    <PRChart
      key={index}
      title={article.title.toUpperCase()}
      date={article.date}
      content={article.source.toUpperCase()}
      linkText={article.url}
      linkUrl={article.url}
     lantoken={article.lan}
    />
  ))
)}
          </div>
        </div>
      </div>
    );
  };
  
  export default Press;

