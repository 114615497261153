// About.js
import React from 'react';
import { Link } from "react-router-dom";
import BackButton from "../components/BackButton.js"
import "./contact.css"
import BurgerMenu from '../components/BurgerMenu.js';
import ContactForm from './ContactForm.js';
const Contact = () => {
  return (
    <div ><div className="topblocker" ><BackButton/><BurgerMenu/></div>
  <div className="contaip"style={{  marginTop:"277px"}}>
    <div className="menu8">
     <ul> <li><Link to="/Work">WORK</Link></li>
<li><Link to="/About">ABOUT</Link></li>
<li><Link to="/Exhibitions">EXHIBITIONS</Link></li>
<li><Link to="/Press">PRESS</Link></li>
<li><Link to="/Contact">CONTACT</Link></li></ul></div>
<div className="placeholderc"></div>
<div className="celayer"><ContactForm/></div>

<div className="impressum">
<h2>IMPRINT</h2>



<p>All rights reserved. The copyright for all material published on this website is retained. Reproduction or use of objects such as images, photos, and texts is not permitted without prior written consent from Billi Thanner.</p>
<br/>
<p>Billi Thanner is not responsible for the content of any external websites linked or referred to on this site. These contents are not monitored and are the responsibility of the respective providers.</p>
<br/>
<p>Content: © Billi Thanner, Wien 2024</p>
<br/>
<p>Webdesign: Büro X, <a href="https://buerox.at"target="_blank"
        rel="noopener noreferrer">buerox.at</a></p>
<br/>
<p>Programmierung: Niklas Geiß</p>
</div></div>
</div>

  
    
  );
};

export default Contact;
