import React from 'react';
import "./chart.css";

const PRChart = ({ title, date, content, linkText, linkHref, lantoken }) => {
  return (
    <div className="chart" style={styles.container}>
      <div style={styles.header}>
        <span style={styles.title} lang={lantoken || "de"}>
          {title}
          <br />
          <span style={styles.date}>{date}</span>
        </span>
      </div>
      <p style={styles.content}>{content}</p>
      <a
        href={linkText}
        style={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkText}
      </a>
    </div>
  );
};


const styles = {
  container: {
    border: '2.7px solid black',
    maxWidth: '490px',
    paddingTop: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px'
   
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    gap: '10px',
    marginBottom: '30px',
    
  },
  title: {
    fontSize: '53px',
    color: 'black',
    margin: 0,
    
  },
  date: { wordWrap: "normal",
    fontSize: '53px',
    color: '#00ff00',
  },
  content: {
    fontSize: '24px',
    color: 'black',
    marginBottom: '24px',
  },
  link: {
   
    fontSize: '18px',
    color: 'black',
    textDecoration: 'none',
  },
};

export default PRChart;
